section.heroBanner {
    background-color: #f8f9fa;
    padding: 80px 0;
}
section.heroBanner ul li {
    list-style-type: none;
    width: 33.33%;
    height: 200px;
    position: relative;
    margin: 0;
    float: left;
    color: #fff;
}
section.heroBanner ul li:before {
    content: '';
    background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(0,0,0,1));
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 01;
}

section.heroBanner ul {
    padding: 0;
    position: relative;
}

section.heroBanner img {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
section.heroBanner ul li:nth-child(2) {
    position: absolute;
    left: 0;
    top: 210px;
}

section.heroBanner ul li:nth-child(3) {
    position: absolute;
    right: 0;
}
section.heroBanner ul li:nth-child(4) {
    position: absolute;
    right: 0;
    top: 210px;
}
section.heroBanner ul li:last-child {
    height: 410px;
    left: 1%;
    width: 31.5%;
}
.heroBannerInner {
    padding: 20px;
    display: flex;
    align-items: flex-end;
    height: 100%;
    position: relative;
    z-index: 02;
}
.heroBannerInner h2{
    font-size: 16px;
    font-weight:600;
    text-decoration: none;
    line-height: 18px;
    margin-top: 20px;
    margin-bottom: 0;
}
.heroBannerInner a{
    text-decoration:none;
    color:#fff
}
.heroBannerInner b{
    font-size: 12px;
    padding: 1px 10px 2px;
    display: inline-block;
    border-radius: 4px;
}
.heroBannerInner span{
    color:#a2a8ad;
    font-size: 12px;
    font-weight: 300;
}

@media only screen and (max-width: 812px) {
    section.heroBanner ul li {
        width: 100%!important;
        position: relative!important;
        float: none;
        left: 0!important;
        top: 0!important;
        height: 200px!important;
        margin-bottom: 10px;
    }

    section.heroBanner ul li:last-child {
        margin-bottom: 0px!important;
    }
}