@media (min-width: 768px){
    .recentPosts {
        padding: 5em 0;
    }
}
.h2, h2 {
    font-size: 2rem;
}
.recentPosts h3 {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 700;
}
.postCategory {
    display: inline-block;
    padding: 2px 10px;
    border-radius: 4px;
    letter-spacing: .2em;
    font-weight: 700;
    font-size: .6rem;
    text-transform: uppercase;
    color: #fff;
}
.postMeta {
    color: #b3b3b3;
    font-size: 13px;
    width: 100%;
    display: block;
}
.postText{
    font-size: 1rem;
    font-weight: 300;
}
.postAnchor{
    text-decoration: none;
    font-weight: 300;
    font-size: 1rem;
}
a.pagination__link {}

.pagination li {
    background-color: #0d6efd;
    color: #fff;
    margin: 0 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    border-radius: 50%;
    min-height: 30px;
}

.pagination li a {
    color: #fff;
    text-decoration: none;
}

li.pagination__link--active {
    background-color: #fff;
    color: black;
}

li.pagination__link--disabled {
    background-color: #aaa;
}