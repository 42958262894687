body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* RESET */
body {
  line-height: 1.7;
  color: gray;
  font-weight: 300;
  font-size: 1rem;
  font-family: "Muli",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
a {
  text-decoration: none;
  color: #000;
}
h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5, a, p {
  font-family: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5{color: #000;}


p, a{font-size: 1rem;font-weight: 300;}

.bg-light{
  background-color: #f8f9fa!important;
  color: gray;
}
.siteSinglePage{padding-bottom: 60px;}

.site-footer {
    padding: 4em 0;
    background: #333;
}
.site-footer .footer-heading {
    font-size: 16px;
    color: #fff;
}

.site-footer h2, .site-footer h3, .site-footer h4, .site-footer h5 {
    color: #fff;
}
.site-footer p {
    color: #737373;
}
.site-footer a {
    color: #999;
}
.site-footer a:hover {
    color: #fff;
}
.mr-2{margin-right: 2rem;}
@media (min-width: 768px){
    .site-footer {
        padding: 5em 0;
    }
    
}

@media only screen and (max-width: 767px) {
    .site-footer a{
        padding-left:0;
        padding-right:0;
    }
    
}


/* Header */
header {
    display: flex;
    justify-content: space-between;
    padding: 6px 20px;
    align-items: center;
  }
  
  header ul {
    display: flex;
  }
  
  header ul li {
    list-style-type: none;
    padding-left: 20px;
  }
section.heroBanner {
    background-color: #f8f9fa;
    padding: 80px 0;
}
section.heroBanner ul li {
    list-style-type: none;
    width: 33.33%;
    height: 200px;
    position: relative;
    margin: 0;
    float: left;
    color: #fff;
}
section.heroBanner ul li:before {
    content: '';
    background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(0,0,0,1));
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 01;
}

section.heroBanner ul {
    padding: 0;
    position: relative;
}

section.heroBanner img {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
section.heroBanner ul li:nth-child(2) {
    position: absolute;
    left: 0;
    top: 210px;
}

section.heroBanner ul li:nth-child(3) {
    position: absolute;
    right: 0;
}
section.heroBanner ul li:nth-child(4) {
    position: absolute;
    right: 0;
    top: 210px;
}
section.heroBanner ul li:last-child {
    height: 410px;
    left: 1%;
    width: 31.5%;
}
.heroBannerInner {
    padding: 20px;
    display: flex;
    align-items: flex-end;
    height: 100%;
    position: relative;
    z-index: 02;
}
.heroBannerInner h2{
    font-size: 16px;
    font-weight:600;
    text-decoration: none;
    line-height: 18px;
    margin-top: 20px;
    margin-bottom: 0;
}
.heroBannerInner a{
    text-decoration:none;
    color:#fff
}
.heroBannerInner b{
    font-size: 12px;
    padding: 1px 10px 2px;
    display: inline-block;
    border-radius: 4px;
}
.heroBannerInner span{
    color:#a2a8ad;
    font-size: 12px;
    font-weight: 300;
}

@media only screen and (max-width: 812px) {
    section.heroBanner ul li {
        width: 100%!important;
        position: relative!important;
        float: none;
        left: 0!important;
        top: 0!important;
        height: 200px!important;
        margin-bottom: 10px;
    }

    section.heroBanner ul li:last-child {
        margin-bottom: 0px!important;
    }
}
@media (min-width: 768px){
    .recentPosts {
        padding: 5em 0;
    }
}
.h2, h2 {
    font-size: 2rem;
}
.recentPosts h3 {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 700;
}
.postCategory {
    display: inline-block;
    padding: 2px 10px;
    border-radius: 4px;
    letter-spacing: .2em;
    font-weight: 700;
    font-size: .6rem;
    text-transform: uppercase;
    color: #fff;
}
.postMeta {
    color: #b3b3b3;
    font-size: 13px;
    width: 100%;
    display: block;
}
.postText{
    font-size: 1rem;
    font-weight: 300;
}
.postAnchor{
    text-decoration: none;
    font-weight: 300;
    font-size: 1rem;
}
a.pagination__link {}

.pagination li {
    background-color: #0d6efd;
    color: #fff;
    margin: 0 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    border-radius: 50%;
    min-height: 30px;
}

.pagination li a {
    color: #fff;
    text-decoration: none;
}

li.pagination__link--active {
    background-color: #fff;
    color: black;
}

li.pagination__link--disabled {
    background-color: #aaa;
}

@media only screen and (max-width: 767px) {
    section.recentPosts {
        padding-top: 50px;
    }

    section.recentPosts .mb-5 {
        margin-bottom: 1rem!important;
    }
    .postInner {
        margin-bottom: 20px;
    }
}
.site-section {
    padding: 2.5em 0;
}
.subscribe-1 h2 {
    font-size: 20px;
}
.subscribe-1 input, .subscribe-1 .btn{
    border-radius: 0;
}
@media (min-width: 768px){
    .site-section {
        padding: 5em 0;
    }
}
.mapIframe iframe{
    width: 100%;
    height: 300px;
}
.comment-history {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .comment-history:nth-child(even) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  
  .comment {
    padding: 1rem;
    width: 80%;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  
.bannerFull {
    height: calc(100vh - 66px);
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    margin-bottom: 60px;
}
.bannerFull.bannerInnerPages {
    height: 300px;
}
.overlay:after {
    content: '';
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
}
.bannerFull .row{
    position: relative;
    z-index: 1;
}
.bannerFull h1{
    color: #fff;
}


.bannerFull h1 {
    font-size: 2rem;
    color: #fff;
}
@media (min-width: 992px){
    .bannerFull h1 {
        font-size: 5rem;
    }
}
@media (min-width: 768px){
    .recentPosts {
        padding: 5em 0;
    }
}
.h2, h2 {
    font-size: 2rem;
}
.recentPosts h3 {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 700;
}
.postCategory {
    display: inline-block;
    padding: 2px 10px;
    border-radius: 4px;
    letter-spacing: .2em;
    font-weight: 700;
    font-size: .6rem;
    text-transform: uppercase;
    color: #fff;
}
.postMeta {
    color: #b3b3b3;
    font-size: 13px;
    width: 100%;
    display: block;
}
.postText{
    font-size: 1rem;
    font-weight: 300;
}
.postAnchor{
    text-decoration: none;
    font-weight: 300;
    font-size: 1rem;
}
a.pagination__link {}

.pagination li {
    background-color: #0d6efd;
    color: #fff;
    margin: 0 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    border-radius: 50%;
    min-height: 30px;
}

.pagination li a {
    color: #fff;
    text-decoration: none;
}

li.pagination__link--active {
    background-color: #fff;
    color: black;
}

li.pagination__link--disabled {
    background-color: #aaa;
}
@media (min-width: 768px){
    .blogsPage .recentPosts {
        padding: 0;
    }
}
.blogsPage .recentPosts h2{
    display: none;
}
.blogsPage .recentPosts .mb-5{
    margin-bottom: 0!important;
}
.blogsPage .pagination{margin-top: 40px;}
.bannerFull {
    height: calc(100vh - 66px);
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    margin-bottom: 60px;
}
.bannerFull.bannerInnerPages {
    height: 300px;
}
.overlay:after {
    content: '';
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
}
.bannerFull .row{
    position: relative;
    z-index: 1;
}
.bannerFull h1{
    color: #fff;
}


.bannerFull h1 {
    font-size: 2rem;
    color: #fff;
}
@media (min-width: 992px){
    .bannerFull h1 {
        font-size: 5rem;
    }
}
